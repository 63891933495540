import React, { useState, useEffect } from "react";
import ls from "../services/ls";
import api from "../services/api";
import swal from "sweetalert";
import { __ } from "services/Translator";

import { Modal, Button, Alert } from "react-bootstrap";

const GeoRestriction = (props) => {
  const isAuthenticated = props.isAuthenticated;

  const ping = async () => {
    var response = await api.get("/geo-ping");
    if (response.status === 403) {
      //Forbiden access
      swal({
        title: __("Warning!!"),
        text: response.data.message,
        icon: "error",
        buttons: {
          cancel: {
            text: __("Close"),
            value: null,
            visible: true,
            className: "",
            closeModal: true,
          },
        },
        dangerMode: true,
      });
    } else if (response.status === 451) {
      //Continue with warning of legal issues
      var message;
      if (response.data.restriction_on === "country") {
        message = `
          <p style="color: #ffffff">We're thrilled to see your interest in our platform. Please note that access to some features may be restricted based on your location due to regulatory requirements.</p>
          <p style="color: #ffffff">We appreciate your understanding and look forward to providing an exciting experience tailored to you.</p>
        `;
      } else {
        message = response.data.message;
      }
      swal({
        title: __("Welcome to Blizz!"),
        content: {
          element: "div",
          attributes: {
            innerHTML: message, // Use innerHTML to insert raw HTML content
          },
        },
        icon: "error",
        buttons: {
          cancel: {
            text: __("Close"),
            value: null,
            visible: true,
            className: "",
            closeModal: true,
          },
          confirm: {
            text: __("Continue"),
            value: true,
            visible: true,
            className: "",
            closeModal: true,
          },
        },
        dangerMode: true,
      }).then((value) => {
        if (value) {
          // Callback function on continue click
          ls.set("geoexception", "User has accepted the exception");
        }
      });
    }
  };

  useEffect(() => {
    ping();
  }, []);

  return <></>;
};

export default GeoRestriction;
